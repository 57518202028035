import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import getImageUrl from '../../../modules/getImageUrl';
import type { Deal } from '../../../types/Deal';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/amazon-prime-shipping.css';

interface Props {
  deal: Deal;
  showLink: boolean;
  className?: string;
  width?: number;
  height?: number;
}

loadCSS('amazon-prime-shipping');

const AmazonPrimeShipping = (props: Props): JSX.Element | null => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        translate,
        genericSharedComponents: { AffiliateLink, LazyImage },
      } = value;
      let { deal } = props;
      const { showLink, className, width, height } = props;
      deal = deal || {};

      const innerHTML = {
        __html: translate('amazonPrimeShippingLabel', ['<strong>', '</strong>']),
      };
      const getContainerStyle = (): string => {
        const containerStyle = className && styles[className] ? className : 'container';

        if (containerStyle === 'pricedeal-button') {
          return containerStyle;
        }
        return 'prime-shipping';
      };

      return (
        <div className={styles[getContainerStyle()]}>
          <AffiliateLink
            {...{
              deal,
              label: 'Free trial',
              className: getContainerStyle(),
              clickType: 'Amazon Prime',
            }}
          >
            <LazyImage
              {...{
                src: getImageUrl('misc/prime.png'),
                alt: translate('amazonPrimeFlag'),
                width: width || 50,
                height: height || 21,
                className,
              }}
            />
            {showLink && (
              <span
                className={styles.link}
                // We can disable this warnign because we are sure its sanitized HTML
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={innerHTML}
              />
            )}
          </AffiliateLink>
        </div>
      );
    }}
  </Consumer>
);

export default AmazonPrimeShipping;
